import * as React from "react"

import Seo from "../components/Seo"
import DiscountServices from "../components/DiscountServices"
import Benefits from "../components/BenefitsTitle"

const BenefitsPage = () => (
  <>
    <Seo title="Beneficios" />
    <Benefits />
    <div className="discountservices-spacing" />
    <DiscountServices />
  </>
)

export default BenefitsPage
