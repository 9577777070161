import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const Benefits = () => (
  <section className="titlesecondary-section">
    <div className="workenvironment-box-div">
      <div className="titlesecondary-description-box">
        <div className="workenvironment-box-title-grid">
          <div />
          <div
            className="workenvironment-cube"
            style={{ backgroundColor: `red` }}
          />
          <p className="workenvironment-title">Becas y Convenios</p>
          <div
            className="workenvironment-cube"
            style={{ backgroundColor: `red` }}
          />
          <div />
        </div>
        <Link style={{ textDecoration: "none" }} to="/becas-comunidad">
          <div className="benefits-box">
            <div className="benefits-icon-box">
              <div className="benefits-icon-before" />
              <StaticImage
                placeholder="blurred"
                src="../images/Comunidad cenhies.png"
                alt=""
                className={`benefits-icon`}
                style={{
                  filter:
                    "invert(18%) sepia(75%) saturate(6149%) hue-rotate(356deg) brightness(105%) contrast(119%)",
                }}
              />
            </div>
            <div className="benefits-description">
              <div
                style={{ backgroundColor: `red` }}
                className="benefits-description-shape"
              />
              <div className="benefits-description-box">
                <p
                  style={{ borderColor: `red` }}
                  className="benefits-description-title"
                >
                  Becas Comunidad <br />
                  CENHIES
                </p>
              </div>
            </div>
          </div>
        </Link>
        <br />
        <Link
          style={{ textDecoration: "none" }}
          to="/becas-comunidad-extendida"
        >
          <div className="benefits-box">
            <div className="benefits-icon-box">
              <div className="benefits-icon-before" />
              <StaticImage
                placeholder="blurred"
                src="../images/Comunidad Extendida.png"
                alt=""
                className={`benefits-icon`}
                style={{
                  filter:
                    "invert(18%) sepia(75%) saturate(6149%) hue-rotate(356deg) brightness(105%) contrast(119%)",
                }}
              />
            </div>
            <div className="benefits-description">
              <div
                style={{ backgroundColor: `red` }}
                className="benefits-description-shape"
              />
              <div className="benefits-description-box">
                <p
                  style={{ borderColor: `red` }}
                  className="benefits-description-title"
                >
                  Becas Comunidad <br />
                  Extendida
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
    <StaticImage
      placeholder="blurred"
      src="../images/becas.png"
      alt=""
      style={{ borderColor: `red` }}
      className="titlemastery-philosophy-image"
    />
  </section>
)

export default Benefits
